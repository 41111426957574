import { render, staticRenderFns } from "./privacy-policy.vue?vue&type=template&id=b9542ad0&scoped=true"
var script = {}
import style0 from "./privacy-policy.vue?vue&type=style&index=0&id=b9542ad0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9542ad0",
  null
  
)

export default component.exports